import React from "react";
import { Typography, Box, List, ListItem } from "@mui/material";

const PlansAndItemsInstructions: React.FC = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Plans and Items Instructions
      </Typography>
      <Typography variant="body2" paragraph>
        Some values here should be auto-populated from the quote lookup, so
        please confirm them.
      </Typography>
      <Typography variant="body2" paragraph>
        First, select the appropriate class and subscription plan. When you
        select the subscription plan, the items from that subscription plan
        should auto-populate below. You can either remove them or leave them
        un-included.
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        I don't see the Non-Recurring Product plan I need. What do I do?
      </Typography>
      <Typography variant="body2" paragraph>
        The non-recurring product plan uses{" "}
        <strong>ESW 2020 Base Subscription Plan</strong>. Please select that
        instead.
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        What are all the different values in the Item table?
      </Typography>
      <Typography variant="body2" paragraph>
        Here is a quick summary of the different values in the Item table:
        <List component="ul">
          <ListItem>
            <Typography variant="body2" component="span">
              <strong>Include?:</strong> Whether this item should be included in
              the invoice. Many subscription plans have optional items that you
              can choose not to include for activation and invoicing. You can
              leave those un-included if you like.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" component="span">
              <strong>Item:</strong> The id of the item in NetSuite, either
              associated with your Subscription Plan or added manually by you.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" component="span">
              <strong>Line Memo:</strong> This is a memo to include on the
              invoice. For most subscription plans, these will be condensed into
              a single memo that is shown on the first item on the invoice. For{" "}
              <strong>ESW 2020 Base Subscription Plan</strong>, the invoice will
              show each individual line item with its own memo.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" component="span">
              <strong>Quantity:</strong> The quantity of the item to be included
              in the invoice. Note that this will not listed value.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" component="span">
              <strong>Value:</strong> The total value of this line item (not per
              unit).
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" component="span">
              <strong>Type:</strong> Whether this will be a one-time charge or a
              recurring charge.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" component="span">
              <strong>Frequency:</strong> For recurring charges, how frequently
              this charge will repeat. For one-time charges, this will be "One
              Time".
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" component="span">
              <strong>Total Over Term:</strong> For recurring charges, the total
              value of this charge over the term length. For one-time charges,
              this will be the same as the value.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" component="span">
              <strong>Actions:</strong> These are controls to update or remove
              the listed item.
            </Typography>
          </ListItem>
        </List>
      </Typography>
    </Box>
  );
};

export default PlansAndItemsInstructions;
