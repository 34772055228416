import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import GoogleIcon from "../icons/GoogleIcon";
import { useAuth } from "../../contexts/AuthContext";

const CLIENT_ID = "f47prn5u7ehgvtp067p3enhg6";

const AuthPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [oauthLoading, setOauthLoading] = useState(false);
  const [hasAuthChecked, setHasAuthChecked] = useState(false);
  const { login, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const exchangeToken = useCallback(async () => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
      setOauthLoading(true);
      const backendUrl = "/api/oauth";

      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      });

      const status = response.status;
      const data = await response.json();
      if (status !== 200) {
        setOauthLoading(false);
        setErrorMessage(data.message);
      }

      if (response.ok) {
        setOauthLoading(false);
        login(data.user);

        navigate("/");
      }
    } else {
      console.log("No code found in query parameters.");
    }
  }, [location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
      exchangeToken();
    } else {
      setHasAuthChecked(true); // Indicate that auth check is done if no code is present
    }
  }, [exchangeToken, location]);

  const handleGoogleSignIn = () => {
    const domain = `sso.servicedesk.csaiautomations.com`;
    const responseType = "code";
    const clientId = CLIENT_ID;
    const redirectUri = encodeURIComponent(
      `https://${window.location.hostname}`
    );
    const identityProvider = "Google";
    const scopes = "email+openid+profile";

    const cognitoUrl = `https://${domain}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&identity_provider=${identityProvider}&scope=${scopes}`;

    window.location.href = cognitoUrl;
  };

  return (
    <Container
      style={{
        height: "100vh",
        maxWidth: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#efefef", // Apply the radial gradient background
      }}
    >
      <Box
        maxWidth="xs"
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4" style={{ marginTop: "20px" }}>
          Central Service Desk
        </Typography>
        {errorMessage ? (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                marginTop: "10px",
                textAlign: "center",
                color: "red",
                fontWeight: "bold",
              }}
            >
              Error!
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ marginTop: "10px", textAlign: "center" }}
            >
              {errorMessage}
              <br />
              {errorMessage === "Domain Not Allowed"
                ? ". We restrict site access to Google Workspace domains affiliated with our company."
                : ""}
            </Typography>
          </>
        ) : (
          (oauthLoading || loading) && (
            <CircularProgress style={{ marginTop: "20px" }} />
          )
        )}
        {!oauthLoading && !loading && !errorMessage && (
          <Button
            onClick={handleGoogleSignIn}
            variant="outlined"
            sx={{ mt: 3, mb: 2, p: 1, width: "300px", backgroundColor: "#fff" }}
            startIcon={<GoogleIcon />}
          >
            Sign In With Google
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default AuthPage;
