import React, { useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import UserList from "./UserList";
import NewUserForm from "./NewUserForm";
import { useUsers } from "../../hooks/useUsers";
import { UserRole, Permission } from "models/user";

const UserManagementPage: React.FC = () => {
  const {
    users,
    loading,
    createUser,
    deleteUser,
    updateUserPolicy,
    addPermission,
    removePermission,
  } = useUsers();
  const [newPermission, setNewPermission] = useState<Permission>(
    Permission.Invoice,
  );

  const handleCreateUser = (email: string, role: UserRole) => {
    const newUser = {
      email,
      policies: [{ role, permissions: [] }],
      createdAt: new Date().toISOString(),
    };
    createUser(newUser);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <UserList
          users={users}
          onDelete={deleteUser}
          onUpdatePolicy={updateUserPolicy}
          onAddPermission={addPermission}
          onRemovePermission={removePermission}
          newPermission={newPermission}
          setNewPermission={setNewPermission}
        />
      )}

      <NewUserForm onCreate={handleCreateUser} />
    </Box>
  );
};

export default UserManagementPage;
