import { useState, useEffect } from "react";
import { useInvoiceFormContext } from "../../contexts/InvoiceFormContext";
import { useAuth } from "../../contexts/AuthContext";

export const useQuoteData = () => {
  const { invoiceFormData, setInvoiceFormData } = useInvoiceFormContext();
  const [loadingQuotes, setLoadingQuotes] = useState(false);
  const [loadingQuoteDetails, setLoadingQuoteDetails] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [customerQuotes, setCustomerQuotes] = useState([]);
  const { fetchWithReauth } = useAuth();

  useEffect(() => {
    if (invoiceFormData.customerId) {
      const fetchCustomerQuotes = async () => {
        setLoadingQuotes(true);
        setError(null);
        try {
          const response = await fetchWithReauth(
            `/api/netsuite/customerQuotes?customerId=${invoiceFormData.customerId}`,
          );
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error("No quotes found for this customer.");
            } else {
              throw new Error("Failed to fetch quotes");
            }
          }
          const data = await response.json();
          setCustomerQuotes(data);
        } catch (e) {
          const error = e as Error;
          setError(
            error.message === "No quotes found for this customer."
              ? error.message
              : "An error occurred while fetching the quotes.",
          );
        } finally {
          setLoadingQuotes(false);
        }
      };

      fetchCustomerQuotes();
    }
  }, [invoiceFormData.customerId]);

  const handleQuoteLookup = async (quoteId: string) => {
    setLoadingQuoteDetails(true);
    setError(null);
    setSuccess(false);
    try {
      const response = await fetchWithReauth(
        `/api/netsuite/invoice-from-quote?quoteId=${quoteId}`,
      );
      if (!response.ok) {
        if (response.status === 404) {
          setError("Quote not found. Please check the quote ID and try again.");
        } else {
          setError(
            "An error occurred while fetching the quote. Please try again later.",
          );
        }
        setLoadingQuoteDetails(false);
        return;
      }
      const data = await response.json();
      setInvoiceFormData(data);
      setSuccess(true);
    } catch (error) {
      setError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoadingQuoteDetails(false);
    }
  };

  return {
    customerQuotes,
    loadingQuotes,
    loadingQuoteDetails,
    error,
    success,
    handleQuoteLookup,
  };
};
