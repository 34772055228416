import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  PassQcOutput,
  ManualExecutionQcOutput,
  ErrorQcOutput,
} from "models/exception-invoice";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Chip,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StatusChip from "../general/StatusChip";
import { useExceptionInvoiceRequest } from "src/hooks/exception-invoices/useExceptionInvoiceRequest";
import { getNetSuiteLink } from "src/util/helpers";

const ExceptionInvoiceQC: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);

  const {
    exceptionInvoiceRequest: qcRecord,
    loading,
    refetch,
  } = useExceptionInvoiceRequest(id!);

  const fetchQcRecord = useCallback(async () => {
    if (id) {
      setRefreshing(true);
      try {
        await refetch();
      } finally {
        setRefreshing(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchQcRecord();
  }, []);

  useEffect(() => {
    if (qcRecord?.status === "IN_PROGRESS") {
      const intervalId = setInterval(() => {
        fetchQcRecord();
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [qcRecord?.status, fetchQcRecord]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!qcRecord) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="body1">
          No Exception Invoice QC record found.
        </Typography>
      </Box>
    );
  }

  const renderOutputDetails = () => {
    if (qcRecord?.output.decision === "PASS") {
      const output = qcRecord.output as PassQcOutput;
      return (
        <>
          <Typography variant="body2">
            <strong>Invoice:</strong>{" "}
            <a href={getNetSuiteLink("Invoice", output.invoiceId)}>
              {output.tranId}
            </a>
          </Typography>
          <Typography variant="body2">
            <strong>Was Sent:</strong> {output.wasSent ? "Yes" : "No"}
          </Typography>
          {output.toList && output.toList.length > 0 && (
            <Typography variant="body2">
              <strong>To List:</strong> {output.toList.join(", ")}
            </Typography>
          )}
          {output.ccList && output.ccList.length > 0 && (
            <Typography variant="body2">
              <strong>CC List:</strong> {output.ccList.join(", ")}
            </Typography>
          )}
          <Typography variant="body2">
            <strong>Needs Upload:</strong> {output.needsUpload ? "Yes" : "No"}
          </Typography>
          {output.uploadWorkUnit && (
            <Typography variant="body2">
              <strong>Upload Work Unit:</strong>{" "}
              <a
                href={`https://devgraph-alp.atlassian.net/browse/${output.uploadWorkUnit}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {output.uploadWorkUnit}
              </a>
            </Typography>
          )}
        </>
      );
    } else if (
      qcRecord?.output.decision === "MANUAL_EXECUTION" ||
      qcRecord?.output.decision === "ERROR"
    ) {
      const output = qcRecord.output as ManualExecutionQcOutput | ErrorQcOutput;
      return (
        <>
          <Typography variant="body2">
            <strong>Reasoning:</strong> {output.reasoning}
          </Typography>
          <Typography variant="body2">
            <strong>Work Unit:</strong>{" "}
            <a
              href={`https://devgraph-alp.atlassian.net/browse/${output.workUnit}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {output.workUnit}
            </a>
          </Typography>
        </>
      );
    }
    return null;
  };

  return (
    <Box p={3}>
      {qcRecord ? (
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box display="flex" alignItems="center" mb={3} gap={2}>
            <IconButton
              onClick={() => navigate("/exception-invoices")}
              size="small"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">XINVREQ-{qcRecord.requestId}</Typography>
            <StatusChip status={qcRecord.status} />
            <Chip
              label={qcRecord.output.decision}
              color={
                qcRecord.output.decision === "PASS"
                  ? "success"
                  : qcRecord.output.decision === "ERROR"
                  ? "error"
                  : "warning"
              }
            />
            {refreshing && <CircularProgress size={24} />}
          </Box>
          <Box mb={3}>
            <Typography variant="h6">Input Details</Typography>
            <Typography variant="body2">
              <strong>Subscription ID:</strong>{" "}
              <a
                href={getNetSuiteLink(
                  "Subscription",
                  qcRecord.input.subscriptionId
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                {qcRecord.input.subscriptionId}
              </a>
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="h6" gutterBottom>
              Output Details
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2">
                <strong>Decision:</strong>
              </Typography>
              <Chip
                label={qcRecord.output.decision}
                color={
                  qcRecord.output.decision === "PASS"
                    ? "success"
                    : qcRecord.output.decision === "ERROR"
                    ? "error"
                    : "warning"
                }
              />
            </Box>
            <Box mt={2}>{renderOutputDetails()}</Box>
          </Box>
        </Paper>
      ) : (
        <Typography variant="body1">
          No Exception Invoice QC record found.
        </Typography>
      )}
    </Box>
  );
};

export default ExceptionInvoiceQC;
