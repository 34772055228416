import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Divider,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSubscriptionQcRequests } from "../../hooks/subscription-qc/useSubscriptionQcRequests";
import StatusChip from "../general/StatusChip";
import { SubscriptionQc } from "models/subscription-qc";

const SubscriptionQCs: React.FC = () => {
  const [selectedMonth, setSelectedMonth] = useState<string>(
    generateCurrentMonthValue()
  );
  const navigate = useNavigate();
  const months = generateMonths();

  const {
    subscriptionQcRequests: records,
    loading,
    fetchSubscriptionQcRequestsByMonth,
  } = useSubscriptionQcRequests();

  useEffect(() => {
    fetchSubscriptionQcRequestsByMonth(selectedMonth);
  }, [selectedMonth]);

  const handleMonthChange = (event: SelectChangeEvent) => {
    setSelectedMonth(event.target.value);
  };

  const groupRecordsByDate = (
    records: SubscriptionQc[]
  ): { [date: string]: SubscriptionQc[] } => {
    return records.reduce((groups, record) => {
      const date = record.createdAt.substring(0, 10); // Group by date (YYYY-MM-DD)
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(record);
      return groups;
    }, {} as { [date: string]: SubscriptionQc[] });
  };

  const groupedRecords = groupRecordsByDate(records);

  return (
    <Container sx={{ pt: 4 }}>
      <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
        <IconButton
          onClick={() => navigate("/")}
          size="small"
          sx={{ alignSelf: "flex-start", flexGrow: 0 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ flexGrow: 1, textAlign: "center" }}>
          Subscription QC
        </Typography>
        <Box sx={{ flexGrow: 0, width: "48px" }} />
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
        <Typography variant="h6" mr={2}>
          Select Month:
        </Typography>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          displayEmpty
          inputProps={{ "aria-label": "Select Month" }}
        >
          {months.map((month) => (
            <MenuItem key={month.value} value={month.value}>
              {month.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Divider sx={{ mb: 4 }} />

      <Typography variant="h5" gutterBottom>
        Subscription QC Records
      </Typography>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : Object.keys(groupedRecords).length === 0 ? (
        <Typography>No subscription QC records found</Typography>
      ) : (
        <Box>
          {Object.keys(groupedRecords).map((date) => (
            <Box key={date} sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                {new Date(date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </Typography>
              {groupedRecords[date].map((record) => (
                <Box
                  key={record.requestId}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 1,
                    borderBottom: "1px solid #ccc",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                  onClick={() =>
                    navigate(`/subscription-qc/SUBQC-${record.requestId}`)
                  }
                >
                  <Box sx={{ flex: "1 1 20%" }}>
                    <Typography variant="body1">
                      SUBQC-{record.requestId}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 1 30%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Chip
                      label={`Subscription ID: ${record.input.subscriptionId}`}
                      color="default"
                    />
                  </Box>
                  <Box
                    sx={{
                      flex: "1 1 10%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Chip
                      label={record.output.decision}
                      color={
                        record.output.decision === "PASS" ? "success" : "error"
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      flex: "1 1 10%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <StatusChip status={record.status} />
                  </Box>
                  <Box
                    sx={{
                      flex: "1 1 20%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography variant="body2">
                      {new Date(record.createdAt).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      )}
    </Container>
  );
};

// Helper function to generate the current month value in "YYYY-MM" format
const generateCurrentMonthValue = (): string => {
  return new Date().toISOString().substring(0, 7);
};

// Helper function to generate months from 08/2024 to the current month
const generateMonths = (): { value: string; label: string }[] => {
  const startMonth = new Date(2024, 7, 2, 12, 0, 0); // August 2024
  const currentMonth = new Date();
  const months = [];

  for (
    let dt = new Date(startMonth);
    dt <= currentMonth;
    dt.setMonth(dt.getMonth() + 1)
  ) {
    const value = dt.toISOString().substring(0, 7);
    const label = dt.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
    });
    months.push({ value, label });
  }

  return months.reverse(); // Reverse to have the latest month first
};

export default SubscriptionQCs;
