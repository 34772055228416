import React, { useEffect } from "react";
import { Box, TextField, MenuItem, Grid } from "@mui/material";
import { useInvoiceFormContext } from "../../../contexts/InvoiceFormContext";
import CustomerAutocomplete from "./fields/CustomerAutocomplete";
import { currencies } from "./options/currency";

const CustomerDetails: React.FC = () => {
  const { invoiceFormData, setInvoiceFormData, setCanAdvance, setBlockReason } =
    useInvoiceFormContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInvoiceFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!invoiceFormData.customerId) {
      setCanAdvance(false);
      setBlockReason("Customer ID is required to proceed.");
    } else if (!invoiceFormData.contactEmail) {
      setCanAdvance(false);
      setBlockReason("Contact Email is required to proceed.");
    } else {
      setCanAdvance(true);
      setBlockReason("");
    }
  }, [
    invoiceFormData.customerId,
    invoiceFormData.contactEmail,
    setCanAdvance,
    setBlockReason,
  ]);

  return (
    <Box>
      <CustomerAutocomplete />
      <TextField
        label="Contact Email"
        name="contactEmail"
        value={invoiceFormData.contactEmail}
        onChange={handleChange}
        fullWidth
        margin="normal"
        multiline
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Shipping Address"
            name="shippingAddress"
            value={invoiceFormData.shippingAddress}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Billing Address"
            name="billingAddress"
            value={invoiceFormData.billingAddress}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <TextField
        select
        label="Currency"
        name="currencyId"
        value={invoiceFormData.currencyId}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default CustomerDetails;
