import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import {
  PsNewBusinessInvoiceRequest,
  PsNewBusinessInvoiceCreationParams,
  InvoiceRequestSummary,
} from "models/invoice";

export class InvoiceRequestService {
  async getRecentInvoiceRequests(
    limit: number,
    lastEvaluatedKey?: string
  ): Promise<{
    data: InvoiceRequestSummary[];
    lastEvaluatedKey?: string;
  }> {
    const queryParams = new URLSearchParams({ limit: limit.toString() });
    if (lastEvaluatedKey) {
      queryParams.append("lastEvaluatedKey", lastEvaluatedKey);
    }
    const response = await fetch(
      `${API_URL}/invoices?${queryParams.toString()}`
    );
    const data = await checkResponse(response);
    return data as {
      data: InvoiceRequestSummary[];
      lastEvaluatedKey?: string;
    };
  }

  async getInvoiceRequestBy(id: string): Promise<PsNewBusinessInvoiceRequest> {
    const response = await fetch(`${API_URL}/invoices/${id}`);
    const data = await checkResponse(response);
    return data as PsNewBusinessInvoiceRequest;
  }

  async createInvoiceRequest(
    invoiceRequestData: PsNewBusinessInvoiceCreationParams
  ): Promise<PsNewBusinessInvoiceRequest> {
    const response = await fetch(`${API_URL}/invoices`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(invoiceRequestData),
    });
    const data = await checkResponse(response);
    return data as PsNewBusinessInvoiceRequest;
  }
}
