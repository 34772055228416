import React, { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import { useInvoiceFormContext } from "../../../../contexts/InvoiceFormContext";
import useNetsuiteLookup from "../../../../hooks/invoices/useNetsuiteLookup";

interface ClassificationOption {
  label: string;
  id: string;
}

const ClassificationAutocomplete: React.FC = () => {
  const { invoiceFormData, setInvoiceFormData } = useInvoiceFormContext();
  const fetchClassificationData = useNetsuiteLookup("classification");
  const [options, setOptions] = useState<ClassificationOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(invoiceFormData.className);
  const [value, setValue] = useState<ClassificationOption | null>(null);

  const throttledFetch = useCallback(
    _.throttle(async (query: string) => {
      setLoading(true);
      const data = await fetchClassificationData(query);
      setOptions(data);
      setLoading(false);
    }, 3000),
    [fetchClassificationData],
  );

  const debouncedInputChange = useCallback(
    _.debounce((value: string) => {
      throttledFetch(value);
    }, 1000),
    [throttledFetch],
  );

  useEffect(() => {
    if (inputValue) {
      debouncedInputChange(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue, debouncedInputChange]);

  useEffect(() => {
    if (invoiceFormData.classId && invoiceFormData.className) {
      setValue({
        label: invoiceFormData.className,
        id: invoiceFormData.classId,
      });
    }
  }, [invoiceFormData.classId, invoiceFormData.className]);

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: ClassificationOption | null,
  ) => {
    if (newValue) {
      setInvoiceFormData((prev) => ({
        ...prev,
        classId: newValue.id,
        className: newValue.label,
      }));
      setValue(newValue);
    } else {
      setInvoiceFormData((prev) => ({
        ...prev,
        classId: "",
        className: "",
      }));
      setValue(null);
    }
  };

  return (
    <Autocomplete
      id="classification-autocomplete"
      options={options}
      getOptionLabel={(option) => option.label}
      onInputChange={handleInputChange}
      onChange={handleChange}
      inputValue={inputValue}
      value={value}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Class"
          fullWidth
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ClassificationAutocomplete;
