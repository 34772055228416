import { useState } from "react";
import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import { RecurringInvoiceQc } from "models/recurring-invoice-qc";
import { useAuth } from "src/contexts/AuthContext";

export const useRecurringInvoiceQcRequest = () => {
  const [recurringInvoiceQcRequest, setRecurringInvoiceQcRequest] =
    useState<RecurringInvoiceQc | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchWithReauth } = useAuth();

  const fetchRecurringInvoiceQcRequestById = async (requestId: string) => {
    setLoading(true);
    try {
      const response = await fetchWithReauth(
        `${API_URL}/invoices/recurring-invoice-qc/${requestId}`,
        { method: "GET" }
      );
      const data = await checkResponse(response);
      setRecurringInvoiceQcRequest(data);
    } catch (error) {
      console.error("Error fetching recurring invoice QC request:", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    recurringInvoiceQcRequest,
    loading,
    fetchRecurringInvoiceQcRequestById,
  };
};
