import React, { useState, useEffect, useCallback } from "react";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import { useInvoiceFormContext } from "../../../../contexts/InvoiceFormContext";
import useNetsuiteLookup from "../../../../hooks/invoices/useNetsuiteLookup";
import _ from "lodash";
interface CustomerOption {
  label: string;
  id: string;
}

const CustomerAutocomplete: React.FC = () => {
  const { invoiceFormData, setInvoiceFormData } = useInvoiceFormContext();
  const fetchCustomerData = useNetsuiteLookup("customer");
  const [options, setOptions] = useState<CustomerOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(invoiceFormData.customerName);
  const [value, setValue] = useState<CustomerOption | null>(null);

  const throttledFetch = useCallback(
    _.throttle(async (query: string) => {
      setLoading(true);
      const data = await fetchCustomerData(query);
      setOptions(data);
      setLoading(false);
    }, 3000),
    [fetchCustomerData],
  );

  const debouncedInputChange = useCallback(
    _.debounce((value: string) => {
      throttledFetch(value);
    }, 1000),
    [throttledFetch],
  );

  useEffect(() => {
    if (inputValue) {
      debouncedInputChange(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue, debouncedInputChange]);

  useEffect(() => {
    if (invoiceFormData.customerId && invoiceFormData.customerName) {
      setValue({
        label: invoiceFormData.customerName,
        id: invoiceFormData.customerId,
      });
    }
  }, [invoiceFormData.customerId, invoiceFormData.customerName]);

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: CustomerOption | null,
  ) => {
    if (newValue) {
      setInvoiceFormData((prev) => ({
        ...prev,
        customerId: newValue.id,
        customerName: newValue.label,
      }));
      setValue(newValue);
    } else {
      setInvoiceFormData((prev) => ({
        ...prev,
        customerId: "",
        customerName: "",
      }));
      setValue(null);
    }
  };

  return (
    <Autocomplete
      id="customer-autocomplete"
      options={options}
      getOptionLabel={(option) => option.label}
      onInputChange={handleInputChange}
      onChange={handleChange}
      inputValue={inputValue}
      value={value}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Customer"
          fullWidth
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CustomerAutocomplete;
