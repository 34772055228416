export interface FileUploadSectionType {
  label: string;
  fileUse: string;
}

export const sections: FileUploadSectionType[] = [
  { label: "Signed Quote", fileUse: "signed-quote" },
  { label: "Purchase Order", fileUse: "purchase-order" },
  { label: "Approval to Invoice from PO", fileUse: "approval-document" },
  { label: "Additional Documents", fileUse: "additional-documents" },
];
