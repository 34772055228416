import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useCreateExceptionInvoiceRequests } from "src/hooks/exception-invoices/useCreateExceptionInvoiceRequest";
import { useSubscriptionLookupForExceptionInvoice } from "src/hooks/exception-invoices/useSubscriptionLookupForExceptionInvoice";
import throttle from "lodash/throttle";

const ExceptionInvoicesForm: React.FC = () => {
  const [subscriptionIdsInput, setSubscriptionIdsInput] = useState<string>("");
  const [subscriptionIds, setSubscriptionIds] = useState<string[]>([]);
  const [rowData, setRowData] = useState<any[]>([]); // Store the row data with status
  const [isCreateRequestComplete, setIsCreateRequestComplete] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const { subscriptions, loading: lookupLoading } =
    useSubscriptionLookupForExceptionInvoice(subscriptionIds);
  const {
    creationResults,
    loading: creationLoading,
    createExceptionInvoiceRequests,
  } = useCreateExceptionInvoiceRequests();

  useEffect(() => {
    if (subscriptions.length > 0) {
      const dataWithStatus = subscriptions.map((sub) => ({
        ...sub,
        status: "", // Initialize status as empty
      }));
      setRowData(dataWithStatus);
    }
  }, [subscriptions]);

  useEffect(() => {
    if (creationResults.length > 0) {
      const updatedRowData = rowData.map((row) => {
        const result = creationResults.find(
          (r: { subscriptionId: string }) =>
            r.subscriptionId === row.subscriptionId
        );
        return result
          ? { ...row, status: result.status }
          : { ...row, status: "UNKNOWN" };
      });
      setRowData(updatedRowData);
      setIsCreateRequestComplete(true);
    }
  }, [creationResults]); // Update row data when creation results change

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscriptionIdsInput(event.target.value);
  };

  const handleLookup = () => {
    const ids = subscriptionIdsInput.split(/[ ,]+/).filter((id) => id.trim());
    setSubscriptionIds(ids);
  };

  const handleCreateRequest = throttle(async () => {
    await createExceptionInvoiceRequests(subscriptionIds);
  }, 3000); // Throttle button click to once every 3 seconds

  const handleReturn = () => {
    navigate("/exception-invoices");
  };

  const columns: GridColDef[] = [
    { field: "subscriptionId", headerName: "Subscription ID", width: 200 },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1, // This makes the column grow to fill the remaining space
    },
    { field: "subsidiary", headerName: "Subsidiary", width: 200 },
    {
      field: "totalReadyCharges",
      headerName: "Total Ready Charges",
      type: "number",
      width: 150,
      valueGetter: (value?: string) => {
        try {
          return parseFloat(value || "0");
        } catch (error) {
          return 0;
        }
      },
      valueFormatter: (value: number) =>
        value.toLocaleString("en-US", {
          // decimal places = 2
          minimumFractionDigits: 2,
        }),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ display: "flex", justifyContent: "center" }}>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mt: 3,
          mb: 3,
          minHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "90vw",
        }}
      >
        <Typography variant="h5" component="h1" align="center">
          Create Exception Invoice Request
        </Typography>

        <TextField
          label="Subscription IDs (space or comma separated)"
          variant="outlined"
          fullWidth
          value={subscriptionIdsInput}
          onChange={handleInputChange}
          onBlur={handleLookup} // Trigger lookup on blur (or this could be a button if preferred)
        />

        <Box sx={{ mt: 2, height: 400, width: "100%" }}>
          <DataGrid
            rows={rowData}
            columns={columns}
            getRowId={(row) => row.subscriptionId}
            disableRowSelectionOnClick
            loading={lookupLoading} // Use the loading attribute to show loading state
            initialState={{
              sorting: {
                sortModel: [{ field: "totalReadyCharges", sort: "desc" }],
              },
            }}
            sortModel={[{ field: "totalReadyCharges", sort: "desc" }]} // Ensures sorting is applied
          />
        </Box>

        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={
              isCreateRequestComplete ? handleReturn : handleCreateRequest
            }
            disabled={
              lookupLoading || creationLoading || subscriptionIds.length === 0
            }
          >
            {creationLoading ? (
              <CircularProgress size={24} />
            ) : isCreateRequestComplete ? (
              "Return To Menu"
            ) : (
              "Create Exception Invoice Request"
            )}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ExceptionInvoicesForm;
