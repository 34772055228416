import { SubscriptionTerm } from "models/netsuite";

export const subscriptionTerms = [
  { label: "Annual - 1 Year", value: SubscriptionTerm.ANNUAL_1_YEAR },
  { label: "Annual - 2 Years", value: SubscriptionTerm.ANNUAL_2_YEARS },
  { label: "Annual - 3 Years", value: SubscriptionTerm.ANNUAL_3_YEARS },
  { label: "Annual - 4 Years", value: SubscriptionTerm.ANNUAL_4_YEARS },
  { label: "Annual - 5 Years", value: SubscriptionTerm.ANNUAL_5_YEARS },
  { label: "Annual - 6 Years", value: SubscriptionTerm.ANNUAL_6_YEARS },
  { label: "Annual - 10 Years", value: SubscriptionTerm.ANNUAL_10_YEARS },
  { label: "Custom Term", value: SubscriptionTerm.CUSTOM_TERM },
];
