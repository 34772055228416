import { useState, useEffect } from "react";
import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import { useAuth } from "src/contexts/AuthContext";

type SubscriptionLookup = {
  subscriptionId: string;
  customerName: string;
  subsidiary: string;
  status?: string;
  totalReadyCharges: number;
};

export const useSubscriptionLookupForExceptionInvoice = (
  subscriptionIds: string[]
) => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionLookup[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { fetchWithReauth } = useAuth();

  const fetchSubscriptionDetails = async (subscriptionIds: string[]) => {
    if (subscriptionIds.length === 0) {
      setSubscriptions([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await fetchWithReauth(
        `${API_URL}/netsuite/subscription-lookup-for-exception-invoice` +
          `?subscriptionIds=${subscriptionIds.join(",")}`
      );
      const data = await checkResponse(response);
      setSubscriptions(data);
    } catch (error) {
      console.error("Error fetching subscription details:", error);
      setError("Failed to fetch subscription details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionDetails(subscriptionIds);
  }, [subscriptionIds]);

  return {
    subscriptions,
    loading,
    error,
    refetch: () => fetchSubscriptionDetails(subscriptionIds),
  };
};
