import { useState, useEffect } from "react";
import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import { ExceptionInvoiceRequest } from "models/exception-invoice";
import { useAuth } from "src/contexts/AuthContext";

export const useExceptionInvoiceRequests = () => {
  const [exceptionInvoiceRequests, setExceptionInvoiceRequests] = useState<
    ExceptionInvoiceRequest[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { fetchWithReauth } = useAuth();

  const fetchExceptionInvoiceRequestsByMonth = async (yearMonth?: string) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();
      if (yearMonth) {
        queryParams.append("yearMonth", yearMonth);
      }

      const response = await fetchWithReauth(
        `${API_URL}/invoices/exception-invoices?${queryParams.toString()}`,
        { method: "GET" }
      );
      const data = await checkResponse(response);
      setExceptionInvoiceRequests(data.items);
    } catch (error) {
      console.error("Error fetching exception invoice requests:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExceptionInvoiceRequestsByMonth(); // Fetch for the current month by default
  }, []);

  return {
    exceptionInvoiceRequests,
    loading,
    fetchExceptionInvoiceRequestsByMonth,
  };
};
