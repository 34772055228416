import { useState } from "react";
import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import { useAuth } from "src/contexts/AuthContext";

interface CreationResult {
  requestId: string;
  subscriptionId: string;
  status: "SUCCESS" | "FAIL";
  message?: string;
}

export const useCreateExceptionInvoiceRequests = () => {
  const [creationResults, setCreationResults] = useState<CreationResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchWithReauth } = useAuth();

  const createExceptionInvoiceRequests = async (subscriptionIds: string[]) => {
    setLoading(true);
    try {
      const response = await fetchWithReauth(
        `${API_URL}/invoices/exception-invoices`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subscriptionIds }),
        }
      );
      const data = await checkResponse(response);
      setCreationResults(data.results);
    } catch (error) {
      console.error("Error creating exception invoice requests:", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    creationResults,
    loading,
    createExceptionInvoiceRequests,
  };
};
