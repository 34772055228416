// InvoiceOutputSteps.tsx
import React from "react";
import { Box, Typography, Link, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import WarningIcon from '@mui/icons-material/Warning';
import NotStartedIcon from "@mui/icons-material/NotStarted";
import LinkIcon from "@mui/icons-material/Link";
import { generateNetsuiteLink } from "utils/netsuite";

const isProd = () => {
  return window.location.origin.split(".")[0] === "https://servicedesk";
};

// Base Component
interface SubscriptionTransferStepDisplayBaseProps {
  name: string;
  status: string;
  link?: string;
  children?: React.ReactNode;
}

const SubscriptionTransferStepDisplayBase: React.FC<SubscriptionTransferStepDisplayBaseProps> = ({
  name,
  status,
  link,
  children,
}) => {
  let icon;
  let color;
  let tooltipText;

  switch (status) {
    case "COMPLETED":
      icon = <CheckIcon />;
      color = "green";
      tooltipText = "Completed";
      break;
    case "FAILURE":
      icon = <ErrorIcon />;
      color = "red";
      tooltipText = "Failed";
      break;
    case "IN_PROGRESS":
      icon = <HourglassEmptyIcon />;
      color = "orange";
      tooltipText = "In Progress";
      break;
    case "SKIPPED":
      icon = <WarningIcon />;
      color = "grey";
      tooltipText = "Skipped";
      break;
    case "NOT_INITIATED":
    default:
      icon = <NotStartedIcon />;
      color = "grey";
      tooltipText = "Not Initiated";
      break;
  }

  return (
    <Box mb={2} width="300px" height="60px">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="left"
        gap={1}
        height="32px"
      >
        <Tooltip title={tooltipText}>
          <Box
            component="span"
            color={color}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {icon}
          </Box>
        </Tooltip>
        {link && (
          <Link href={link} target="_blank" rel="noopener noreferrer">
            <LinkIcon />
          </Link>
        )}
        <Typography
          variant="body1"
          flexGrow={1}
          sx={{ fontWeight: "bold", marginRight: 1 }}
        >
          {name}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

// SubsidiaryCheckStep Component
interface CommonStepProps {
  name: string;
  status: string;
  step: number;
  displayId?: string;
  displayUrl?: string;
}

const CommonStep: React.FC<CommonStepProps> = ({
  name,
  status,
  step,
  displayId,
  displayUrl,
}) => (
  <SubscriptionTransferStepDisplayBase
    name={name}
    status={status}
    link={displayId ? generateNetsuiteLink(
      isProd() ? "4914352" : "4914352-sb2",
      "base",
      '',
      ''
    ) + displayUrl: undefined}
  >
    {displayId && (
      <Typography variant="body2" sx={{ paddingTop: 1 }}>
        {displayId}
      </Typography>
    )}
  </SubscriptionTransferStepDisplayBase>
);


export {
  SubscriptionTransferStepDisplayBase,
  CommonStep,
};
