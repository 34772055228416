import { useState } from "react";
import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import { SubscriptionQc } from "models/subscription-qc";
import { useAuth } from "src/contexts/AuthContext";

export const useSubscriptionQcRequest = () => {
  const [subscriptionQcRequest, setSubscriptionQcRequest] =
    useState<SubscriptionQc | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchWithReauth } = useAuth();

  const fetchSubscriptionQcRequestById = async (requestId: string) => {
    setLoading(true);
    try {
      const response = await fetchWithReauth(
        `${API_URL}/invoices/subscription-qc/${requestId}`,
        { method: "GET" }
      );
      const data = await checkResponse(response);
      setSubscriptionQcRequest(data);
    } catch (error) {
      console.error("Error fetching subscription QC request:", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    subscriptionQcRequest,
    loading,
    fetchSubscriptionQcRequestById,
  };
};
