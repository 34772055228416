import React, { useEffect, useState } from "react";
import { Container, Typography, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InvoiceRequestService } from "../../services/InvoiceRequestService";
import { InvoiceRequestSummary } from "models/invoice";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import BarChartIcon from "@mui/icons-material/BarChart";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Chip from "@mui/material/Chip";
import RequestCard from "./display-components/RequestCard"; // Ensure the import path is correct
import StatusChip from "../general/StatusChip"; // Ensure the import path is correct
import TypeChip from "./display-components/TypeChip";

const invoiceRequestService = new InvoiceRequestService();

const Invoices: React.FC = () => {
  const [requests, setRequests] = useState<InvoiceRequestSummary[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const { data } = await invoiceRequestService.getRecentInvoiceRequests(
          15
        );
        setRequests(data);
      } catch (error) {
        console.error("Error fetching invoice requests:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  return (
    <Container sx={{ pt: 4 }}>
      <Box display="flex" alignItems="center" mb={3}>
        <IconButton onClick={() => navigate("/")} size="small">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" ml={2}>
          Invoices
        </Typography>
      </Box>
      <Typography variant="h5" component="h2" gutterBottom>
        New Request
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
        <RequestCard
          icon={<AutorenewIcon fontSize="large" />}
          title="Renewal"
          disabled
        />
        <RequestCard
          icon={<BusinessCenterIcon fontSize="large" />}
          title="PS / New Business"
          onClick={() => navigate("/invoices/ps-new-biz-form")}
        />
        <RequestCard
          icon={<BarChartIcon fontSize="large" />}
          title="Usage"
          disabled
        />
      </Box>
      <Typography variant="h5" component="h2" gutterBottom>
        Recent Requests
      </Typography>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : requests.length === 0 ? (
        <Typography>No invoice requests found</Typography>
      ) : (
        <Box>
          {requests.map((request) => (
            <Box
              key={request.requestId}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
                borderBottom: "1px solid #ccc",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
              onClick={() => navigate(`/invoices/${request.requestId}`)}
            >
              <Box sx={{ flex: "1 1 20%" }}>
                <Typography variant="body1">{request.requestId}</Typography>
              </Box>
              <Box
                sx={{
                  flex: "1 1 30%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Chip label={request.requester} color="default" />
              </Box>
              <Box
                sx={{
                  flex: "1 1 15%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TypeChip type={request.type} />
              </Box>
              <Box
                sx={{
                  flex: "1 1 15%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <StatusChip status={request.status} />
              </Box>
              <Box
                sx={{
                  flex: "1 1 20%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Typography variant="body2">
                  {new Date(request.createdAt).toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Container>
  );
};

export default Invoices;
