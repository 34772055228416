import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Chip,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StatusChip from "../general/StatusChip";
import { getNetSuiteLink } from "src/util/helpers";
import { useSubscriptionQcRequest } from "../../hooks/subscription-qc/useSubscriptionQcRequest";

const SubscriptionQC: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);

  const {
    subscriptionQcRequest: qcRecord,
    loading,
    fetchSubscriptionQcRequestById,
  } = useSubscriptionQcRequest();

  const fetchQcRecord = useCallback(async () => {
    if (id) {
      const lookupId = id.split("-")[1]; // Extract the ID part from 'SUBQC-ANBRSU'
      setRefreshing(true);

      try {
        await fetchSubscriptionQcRequestById(lookupId);
      } finally {
        setRefreshing(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchQcRecord();
  }, []);

  useEffect(() => {
    if (qcRecord?.status === "IN_PROGRESS") {
      const intervalId = setInterval(() => {
        fetchQcRecord();
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [qcRecord?.status]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!qcRecord) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="body1">
          No Subscription QC record found.
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={3}>
      {qcRecord ? (
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box display="flex" alignItems="center" mb={3} gap={2}>
            <IconButton
              onClick={() => navigate("/subscription-qc")}
              size="small"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">SUBQC-{qcRecord.requestId}</Typography>
            <Chip
              label={`Subscription ID: ${qcRecord.input.subscriptionId}`}
              color="default"
            />
            <StatusChip status={qcRecord.status} />
            <Chip
              label={qcRecord.output.decision}
              color={qcRecord.output.decision === "PASS" ? "success" : "error"}
            />
            {refreshing && <CircularProgress size={24} />}
          </Box>
          <Box mb={3}>
            <Typography variant="h6">Input Details</Typography>
            <Typography variant="body2">
              <strong>Subscription ID:</strong>{" "}
              <a
                href={getNetSuiteLink(
                  "Subscription",
                  qcRecord.input.subscriptionId
                )}
              >
                {qcRecord.input.subscriptionId}
              </a>
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="h6" gutterBottom>
              Output Details
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2">
                <strong>Decision:</strong>
              </Typography>
              <Chip
                label={qcRecord.output.decision}
                color={
                  qcRecord.output.decision === "PASS" ? "success" : "error"
                }
              />
            </Box>
          </Box>
          <Box>
            <Typography variant="body2">
              <strong>Reasoning:</strong>
            </Typography>
            {qcRecord.output.reasoning.split("\n").map((line, index) => (
              <Typography key={index} variant="body2" paragraph>
                {line}
              </Typography>
            ))}
            {qcRecord.output.jiraUnitKey && (
              <Typography variant="body2">
                <strong>JIRA Issue:</strong>{" "}
                <a
                  href={`https://devgraph-alp.atlassian.net/browse/${qcRecord.output.jiraUnitKey}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {qcRecord.output.jiraUnitKey}
                </a>
              </Typography>
            )}
            {qcRecord.output.errors && qcRecord.output.errors.length > 0 && (
              <Box mt={2}>
                <Typography variant="body2" color="error">
                  <strong>Errors:</strong>
                </Typography>
                <ul>
                  {qcRecord.output.errors.map((error, index) => (
                    <li key={index}>
                      <Typography variant="body2" color="error">
                        {error}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            )}
          </Box>
        </Paper>
      ) : (
        <Typography variant="body1">
          No Subscription QC record found.
        </Typography>
      )}
    </Box>
  );
};

export default SubscriptionQC;
