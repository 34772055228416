import React from "react";
import { Card, Typography, Tooltip } from "@mui/material";

interface RequestCardProps {
  icon: React.ReactNode;
  title: string;
  onClick?: () => void;
  disabled?: boolean;
}

const RequestCard: React.FC<RequestCardProps> = ({
  icon,
  title,
  onClick,
  disabled,
}) => (
  <Tooltip title={disabled ? "Under Development" : ""}>
    <span>
      <Card
        onClick={onClick}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          gap: 1,
          width: 150,
          height: 100,
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? "not-allowed" : "pointer",
          transition: "transform 0.2s",
          "&:hover": {
            transform: disabled ? "none" : "scale(1.05)",
          },
        }}
      >
        {icon}
        <Typography>{title}</Typography>
      </Card>
    </span>
  </Tooltip>
);

export default RequestCard;
