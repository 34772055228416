import React, { ReactElement } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ServiceCardProps {
  title: string;
  icon: ReactElement;
  path: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, icon, path }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <Paper 
      elevation={3} 
      onClick={handleClick} 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        p: 2, 
        m: 2, 
        cursor: 'pointer', 
        width: 200 
      }}
    >
      <Box sx={{ mb: 1 }}>{icon}</Box>
      <Typography variant="h6">{title}</Typography>
    </Paper>
  );
}

export default ServiceCard;
