import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { OpenAiResult } from "utils/openai";
import { ExtractedDocumentData } from "models/invoice";

const QcResultComponent = ({
  result,
}: {
  result: OpenAiResult<ExtractedDocumentData>;
}) => {
  return (
    <Box sx={{ mt: 2, textAlign: "left" }}>
      <Typography variant="h6">QC Check Result</Typography>
      {result.type === "Success" && (
        <Paper
          sx={{
            p: 2,
            mt: 2,
            bgcolor: "#d4edda",
            borderRadius: 1,
            boxShadow: 1,
          }}
        >
          <Typography>
            <strong>General Summary</strong>
          </Typography>
          <Typography>{result.data.generalSummary}</Typography>
          <Typography>
            <strong>Signatures</strong>
          </Typography>
          <Typography>
            Signed: {result.data.signatures.signed?.toString()}
          </Typography>
          <Typography>Signer: {result.data.signatures.signer}</Typography>
          <Typography>
            Counter-Signed: {result.data.signatures.counterSigned?.toString()}
          </Typography>
          <Typography>
            Counter-Signer: {result.data.signatures.counterSigner}
          </Typography>
          <Typography>Reasoning: {result.data.signatures.reasoning}</Typography>
          {result.data.poInvoiceApproval &&
            result.data.poInvoiceApproval.needsApproval && (
              <>
                <Typography>
                  <strong>PO Invoice Approval</strong>
                </Typography>
                <Typography>
                  Needs Approval:{" "}
                  {result.data.poInvoiceApproval.needsApproval?.toString()}
                </Typography>
                <Typography>
                  Has Approval:{" "}
                  {result.data.poInvoiceApproval.hasApproval?.toString()}
                </Typography>
                <Typography>
                  Reasoning: {result.data.poInvoiceApproval.reasoning}
                </Typography>
              </>
            )}
          <Typography>
            <strong>Addresses</strong>
          </Typography>
          <Typography>
            Shipping Address: {result.data.shippingAddress}
          </Typography>
          <Typography>Billing Address: {result.data.billingAddress}</Typography>

          <Typography>
            <strong>Contact</strong>
          </Typography>
          <Typography>Email: {result.data.contactEmailAddress}</Typography>

          <Typography>
            <strong>Payment Terms</strong>
          </Typography>
          <Typography>{result.data.paymentTerms}</Typography>

          <Typography>
            <strong>Billing Schedule</strong>
          </Typography>
          <Typography>{result.data.billingSchedule}</Typography>
        </Paper>
      )}
    </Box>
  );
};

export default QcResultComponent;
