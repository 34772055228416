import React from "react";
import { Box, Button, Typography, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { VisuallyHiddenInput } from "../../../general/VisuallyHiddenInput";
import { useFileUpload } from "../../../../hooks/invoices/useFileUpload";
import FileItem from "./FileItem";

interface FileUploadSectionProps {
  section: {
    label: string;
    fileUse: string;
  };
  showApprovalSection: boolean;
}

const FileUploadSection: React.FC<FileUploadSectionProps> = ({
  section,
  showApprovalSection,
}) => {
  const { invoiceFormData, handleFileChange, handleFileRemove } =
    useFileUpload();

  const filteredUploadedFileKeys = (
    invoiceFormData.uploadedFileKeys || []
  ).filter((file) => file.fileUse === section.fileUse);

  if (section.fileUse === "approval-document" && !showApprovalSection) {
    return null;
  }

  return (
    <Box key={section.fileUse} sx={{ mt: 2, mb: 4 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "10px",
        }}
      >
        <Typography variant="h6">{section.label}</Typography>
        <Button component="label" variant="text" startIcon={<AddIcon />}>
          Add File
          <VisuallyHiddenInput
            type="file"
            multiple
            onChange={(e) => handleFileChange(e, section.fileUse)}
          />
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        {filteredUploadedFileKeys.map((file) => (
          <FileItem
            key={file.fileName}
            file={file}
            onRemove={handleFileRemove}
          />
        ))}
        {!filteredUploadedFileKeys.length && <Divider />}
      </Box>
    </Box>
  );
};

export default FileUploadSection;
