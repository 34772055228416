import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useInvoiceFormContext } from "../../../contexts/InvoiceFormContext";
import SubscriptionPlanAutocomplete from "./fields/SubscriptionPlanAutocomplete";
import SubscriptionItemGrid from "./fields/SubscriptionItemGrid";
import ClassificationAutocomplete from "./fields/ClassificationAutocomplete";
import useSubscriptionPlanLines from "../../../hooks/invoices/useSubscriptionPlanLines";

const Items: React.FC = () => {
  const { invoiceFormData, setCanAdvance, setBlockReason } = useInvoiceFormContext();

  useSubscriptionPlanLines(invoiceFormData.subscriptionPlanId);

  useEffect(() => {
    const requiredFields = [
      { name: "Class ID", value: invoiceFormData.classId },
      {
        name: "Subscription Plan ID",
        value: invoiceFormData.subscriptionPlanId,
      },
    ];

    const emptyRequiredFields = requiredFields.filter((field) => !field.value);

    const includedItems = invoiceFormData.items.filter((item) => item.include);
    const emptyItemFields = includedItems.reduce((acc, item) => {
      const missingFields = [];
      if (!item.itemId) missingFields.push("Item ID");
      if (item.quantity === undefined || item.quantity === null)
        missingFields.push("Quantity");
      if (item.totalValue === undefined || item.totalValue === null)
        missingFields.push("Total Value");
      if (!item.subscriptionLineType)
        missingFields.push("Subscription Line Type");
      if (!item.chargeFrequency) missingFields.push("Charge Frequency");
      if (missingFields.length > 0) {
        acc.push({ item: item.itemName || "Unnamed Item", missingFields });
      }
      return acc;
    }, [] as { item: string; missingFields: string[] }[]);

    if (emptyRequiredFields.length > 0) {
      setCanAdvance(false);
      setBlockReason(
        `The following fields are required: ${emptyRequiredFields
          .map((field) => field.name)
          .join(", ")}`,
      );
    } else if (
      invoiceFormData.items.length === 0 ||
      includedItems.length === 0
    ) {
      setCanAdvance(false);
      setBlockReason("No items are present or included.");
    } else if (emptyItemFields.length > 0) {
      setCanAdvance(false);
      setBlockReason(
        `The following included items are missing required fields: ${emptyItemFields
          .map((field) => `${field.item} (${field.missingFields.join(", ")})`)
          .join("; ")}`,
      );
    } else {
      setCanAdvance(true);
      setBlockReason("");
    }
  }, [invoiceFormData, setCanAdvance, setBlockReason]);

  return (
    <Box>
      <ClassificationAutocomplete />
      <SubscriptionPlanAutocomplete />
      <SubscriptionItemGrid />
    </Box>
  );
};

export default Items;
