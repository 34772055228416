import { useState } from "react";
import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import { PoNumberReissueRequest } from "models/po-reissue"; // Assuming you have this model
import { useAuth } from "src/contexts/AuthContext";

export const usePoNumberReissueRequest = () => {
  const [poNumberReissueRequest, setPoNumberReissueRequest] =
    useState<PoNumberReissueRequest | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchWithReauth } = useAuth();

  const fetchPoNumberReissueRequestById = async (requestId: string) => {
    setLoading(true);
    try {
      const response = await fetchWithReauth(
        `${API_URL}/invoices/po-reissue/${requestId}`,
        { method: "GET" }
      );
      const data = await checkResponse(response);
      setPoNumberReissueRequest(data);
    } catch (error) {
      console.error("Error fetching PO Number reissue request:", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    poNumberReissueRequest,
    loading,
    fetchPoNumberReissueRequestById,
  };
};
