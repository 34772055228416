import { useEffect, useState } from "react";
import { useInvoiceFormContext } from "../../contexts/InvoiceFormContext";
import { useAuth } from "../../contexts/AuthContext";
import { Item } from "models/invoice";
import { LineChargeFrequency, SubscriptionLineType } from "models/netsuite";

const useSubscriptionPlanLines = (subscriptionPlanId: string | null) => {
  const { fetchWithReauth } = useAuth();
  const { invoiceFormData, setInvoiceFormData } = useInvoiceFormContext();
  const [ isLoadingPlanItems, setIsLoadingPlanItems ] = useState(false);

  useEffect(() => {
    const fetchSubscriptionPlanLines = async (subscriptionPlanId: string) => {
      try {
        const response = await fetchWithReauth(
          `/api/netsuite/subscriptionplanlines?subscriptionId=${subscriptionPlanId}`,
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching subscription plan lines:", error);
        return null;
      }
    };

    const mapSubscriptionPlanLinesToItems = (lines: any[]): Item[] => {
      return lines.map((line) => ({
        itemId: line.item.id,
        itemName: line.item.refName,
        description: line.item.description,
        quantity: 1, // Default quantity, can be adjusted
        totalValue: 0, // Default value, should be fetched or calculated
        subscriptionLineType: line.subscriptionLineType.id,
        chargeFrequency:
          line.subscriptionLineType.id === "1"
            ? LineChargeFrequency.ONE_TIME
            : LineChargeFrequency.ANNUALLY,
        required: line.isRequired || false,
        include: line.isRequired || false,
      }));
    };

    const fillInItem = (prevItem: Item, newItem: Item) => {
      return {
        ...prevItem,
        required: prevItem.required || newItem.required,
        include: prevItem.include || newItem.include,
        description: prevItem.description || newItem.description,
        totalValue: prevItem.totalValue || newItem.totalValue,
        chargeFrequency: prevItem.chargeFrequency || newItem.chargeFrequency,
        subscriptionLineType: prevItem.subscriptionLineType || newItem.subscriptionLineType,
        quantity: prevItem.quantity === 0 ? 0 : prevItem.quantity || newItem.quantity,
      };
    };

    const updateItems = async () => {
      if (subscriptionPlanId) {
        setIsLoadingPlanItems(true);
        const lines = await fetchSubscriptionPlanLines(subscriptionPlanId);
        if (lines) {
          const items = mapSubscriptionPlanLinesToItems(lines);
          setInvoiceFormData((prevData) => ({
            ...prevData,
            items: prevData.items.map((prevItem) => {
              const newItem = items.find(item => item.itemId === prevItem.itemId);
              const nonRequiredItem: Item = { ...prevItem, required: false, };
              return newItem ? fillInItem(nonRequiredItem, newItem) : nonRequiredItem;
            }).concat(items.filter(item => 
              prevData.items.every(prevItem => prevItem.itemId !== item.itemId)
            )),
          }));
        }
      }
      setIsLoadingPlanItems(false);
    };

    updateItems();
  }, [subscriptionPlanId, fetchWithReauth, setInvoiceFormData]);

  return { isLoadingPlanItems };
};

export default useSubscriptionPlanLines;
