import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import useNetsuiteLookup from "../../../../hooks/invoices/useNetsuiteLookup";

interface ItemOption {
  label: string;
  id: string;
}

interface ItemCellAutocompleteProps {
  params: GridRenderEditCellParams;
  option: ItemOption | null;
}

export function ItemCellAutocomplete(props: ItemCellAutocompleteProps) {
  const { params, option } = props;
  const apiRef = useGridApiContext();
  const fetchItemData = useNetsuiteLookup("item");
  const [options, setOptions] = useState<ItemOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>(option?.label || "");
  const [value, setValue] = useState<ItemOption | null>(option || null);

  const throttledFetch = useCallback(
    _.throttle(async (query: string) => {
      setLoading(true);
      const data = await fetchItemData(query);
      setOptions(data);
      setLoading(false);
    }, 3000),
    [fetchItemData],
  );

  const debouncedInputChange = useCallback(
    _.debounce((value: string) => {
      throttledFetch(value);
    }, 1000),
    [throttledFetch],
  );

  useEffect(() => {
    if (inputValue) {
      debouncedInputChange(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue, debouncedInputChange]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
      event.stopPropagation();
      const itemValue = newValue
        ? { id: newValue.id, label: newValue.label }
        : null;
      apiRef.current.setEditCellValue({
        id: params.id,
        field: params.field,
        value: itemValue,
      });
      setValue(itemValue);
      setInputValue(newValue?.label || "");
    },
    [params.id, params.field, apiRef],
  );

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    setInputValue(value);
  };

  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      fullWidth
      options={options}
      freeSolo={false}
      autoHighlight
      loading={loading}
      renderInput={(inputParams) => (
        <TextField
          {...inputParams}
          error={params.error}
          InputProps={{
            ...inputParams.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {inputParams.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
