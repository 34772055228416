import React, { useState, useCallback } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Container,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useInvoiceFormContext } from "../../contexts/InvoiceFormContext";
import { useAuth } from "src/contexts/AuthContext";
import QuoteLookup from "./form-components/QuoteLookup";
import Items from "./form-components/Items";
import FileUpload from "./form-components/FileUpload";
import { InvoiceRequestService } from "../../services/InvoiceRequestService";
import CustomerDetails from "./form-components/CustomerDetails";
import TermDetails from "./form-components/TermDetails";
import AdditionalData from "./form-components/AdditionalData";
import QuoteLookupInstructions from "./display-components/step-instructions/QuoteLookupInstructions";
import DocumentCheckInstructions from "./display-components/step-instructions/DocumentCheckInstructions";
import CustomerDetailsInstructions from "./display-components/step-instructions/CustomerDetailsInstructions";
import TermDetailsInstructions from "./display-components/step-instructions/TermDetailsInstructions";
import PlansAndItemsInstructions from "./display-components/step-instructions/PlansAndItemsInstructions";
import AdditionalDataInstructions from "./display-components/step-instructions/AdditionalDataInstructions";
import _ from "lodash";
import { Permission, UserRole } from "models/user";

const steps = [
  "Quote Lookup",
  "Document Check",
  "Customer Info",
  "Term Info",
  "Plan and Items",
  "Additional Data",
];

const InvoiceForm: React.FC = () => {
  const { invoiceFormData, canAdvance, blockReason } = useInvoiceFormContext();
  const { user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submit button
  const invoiceService = new InvoiceRequestService();
  const navigate = useNavigate();

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true); // Disable the button
    try {
      console.log("Creating invoice with data:", invoiceFormData);
      const params = {
        refNumber: "WILL-BE-ADDED-LATER",
        ...invoiceFormData,
      };
      const invoiceRequest = await invoiceService.createInvoiceRequest(params);
      console.log("Invoice created successfully:", invoiceRequest);
      navigate(`/invoices/invreq-${invoiceRequest.requestId}`);
    } catch (error) {
      console.error("Failed to create invoice:", error);
      setIsSubmitting(false); // Re-enable the button if there's an error
    }
  }, [invoiceFormData, invoiceService, navigate]);

  // Throttle the handleSubmit function
  const throttledSubmit = useCallback(_.throttle(handleSubmit, 3000), [
    handleSubmit,
  ]);

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      await throttledSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleInstructionsOpen = () => {
    setInstructionsOpen(true);
  };

  const handleInstructionsClose = () => {
    setInstructionsOpen(false);
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <QuoteLookup />;
      case 1:
        return <FileUpload />;
      case 2:
        return <CustomerDetails />;
      case 3:
        return <TermDetails />;
      case 4:
        return <Items />;
      case 5:
        return <AdditionalData />;
      default:
        return null;
    }
  };

  const renderInstructionsContent = (step: number) => {
    switch (step) {
      case 0:
        return <QuoteLookupInstructions />;
      case 1:
        return <DocumentCheckInstructions />;
      case 2:
        return <CustomerDetailsInstructions />;
      case 3:
        return <TermDetailsInstructions />;
      case 4:
        return <PlansAndItemsInstructions />;
      case 5:
        return <AdditionalDataInstructions />;
      default:
        return null;
    }
  };

  if (
    !user?.policies.some(
      (pol) =>
        pol.role === UserRole.ADMIN ||
        (pol.role === UserRole.SUPERVISOR &&
          pol.permissions.includes(Permission.Invoice))
    )
  ) {
    return (
      <Container maxWidth="xl">
        <Paper
          elevation={3}
          sx={{
            p: 2,
            mt: 3,
            minHeight: "87vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center content horizontally
            justifyContent: "center", // Center content vertically
            mb: 3,
            fontSize: "0.875rem",
            textAlign: "center", // Center text
          }}
        >
          <Box sx={{ maxWidth: "600px" }}>
            {" "}
            {/* Limit the width of the text content */}
            <Typography variant="h6" component="div" sx={{ mb: 2 }}>
              We're sorry. This service has been discontinued. Please continue
              to raise your invoice requests directly from the Salesforce CRM.
            </Typography>
            <Typography variant="body1">
              If you are having issues with Salesforce, please reach out to a
              platform administrator to help you with your request.
            </Typography>
          </Box>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Paper
        elevation={3}
        sx={{
          p: 2,
          mt: 3,
          minHeight: "87vh",
          display: "flex",
          flexDirection: "column",
          mb: 3,
          fontSize: "0.875rem",
        }}
      >
        <Box
          sx={{
            width: "95%",
            mx: "auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Button variant="text" onClick={() => navigate("/invoices")}>
            Return to Menu
          </Button>
          <Typography variant="h6" component="div" sx={{ fontSize: "1rem" }}>
            PS / New Business Invoicing
          </Typography>
          <Button variant="text" onClick={handleInstructionsOpen}>
            Step Instructions
          </Button>
        </Box>
        <Box sx={{ width: "95%", mx: "auto" }}>
          <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box sx={{ flexGrow: "1" }}>{renderStepContent(activeStep)}</Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          {(user?.policies.find((pol) => pol.role === UserRole.ADMIN) ||
            activeStep === 0) &&
            activeStep !== steps.length - 1 && (
              <Button variant="text" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
          <Box sx={{ flex: "1 1 auto" }} />
          <Tooltip title={!canAdvance ? blockReason : ""} placement="top" arrow>
            <span>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!canAdvance || isSubmitting} // Disable the button if submitting
              >
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Paper>
      <Dialog open={instructionsOpen} onClose={handleInstructionsClose}>
        <DialogTitle>Instructions</DialogTitle>
        <DialogContent>{renderInstructionsContent(activeStep)}</DialogContent>
        <DialogActions>
          <Button onClick={handleInstructionsClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default InvoiceForm;
