export const checkResponse = async (response: Response) => {
  if (!response.ok) {
    const errorDetails = await response.json().catch(() => null);
    throw new Error(
      errorDetails?.message || `HTTP error! status: ${response.status}`
    );
  }
  return response.json();
};

export const isProd =
  window.location.hostname === "servicedesk.csaiautomations.com";

export const getNetSuiteLink = (
  type: "Subscription" | "Invoice" | "Quote",
  id: string | number
) => {
  const baseUrl = isProd
    ? "https://4914352.app.netsuite.com"
    : "https://4914352-sb2.app.netsuite.com";

  let path: string;

  switch (type) {
    case "Subscription":
      path = `/app/accounting/subscription/subscription.nl?id=${id}`;
      break;
    case "Invoice":
      path = `/app/accounting/transactions/custinvc.nl?id=${id}`;
      break;
    case "Quote":
      path = `/app/accounting/transactions/estimate.nl?id=${id}`;
      break;
  }

  return `${baseUrl}${path}`;
};
