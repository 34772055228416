import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Collapse,
  IconButton,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useInvoiceFormContext } from "../../../contexts/InvoiceFormContext";
import { sections } from "../config/fileUploadSections";
import FileUploadSection from "./display/FileUploadSection";
import QcResultComponent from "./display/QcResultComponent";
import { useQcCheck } from "../../../hooks/invoices/useQcCheck";

const FileUpload: React.FC = () => {
  const { invoiceFormData, setCanAdvance, setBlockReason } =
    useInvoiceFormContext();
  const [showApprovalSection, setShowApprovalSection] =
    useState<boolean>(false);
  const [uploadSectionExpanded, setUploadSectionExpanded] =
    useState<boolean>(true);
  const [qcSectionExpanded, setQcSectionExpanded] = useState<boolean>(false);
  const [canStartQc, setCanStartQc] = useState<boolean>(false); // State to track if required files are uploaded

  const { qcResults, qcInProgress, handleQcCheck } = useQcCheck();

  useEffect(() => {
    const hasPO = invoiceFormData.uploadedFileKeys?.some(
      (file) => file.fileUse === "purchase-order",
    );
    const hasSignedQuote = invoiceFormData.uploadedFileKeys?.some(
      (file) => file.fileUse === "signed-quote",
    );
    setShowApprovalSection((hasPO && !hasSignedQuote) || false);
    setCanStartQc(hasPO || hasSignedQuote || false); // Update canStartQc state
  }, [invoiceFormData.uploadedFileKeys]);

  useEffect(() => {
    const noFilesUploaded =
      !invoiceFormData.uploadedFileKeys ||
      invoiceFormData.uploadedFileKeys.length === 0;
    const noQcResults = qcResults === null;

    if (noFilesUploaded) {
      setCanAdvance(false);
      setBlockReason("No files are uploaded.");
    } else if (noQcResults) {
      setCanAdvance(false);
      setBlockReason("You haven't run QC on your documents.");
    } else {
      setCanAdvance(true);
      setBlockReason("");
    }
  }, [
    invoiceFormData.uploadedFileKeys,
    qcResults,
    setCanAdvance,
    setBlockReason,
  ]);

  const startQcCheck = () => {
    setUploadSectionExpanded(false);
    setQcSectionExpanded(true);
    handleQcCheck();
  };

  const toggleUploadSection = () => {
    setUploadSectionExpanded((prev) => !prev);
  };

  const toggleQcSection = () => {
    setQcSectionExpanded((prev) => !prev);
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title="Upload Documents"
          action={
            <IconButton onClick={toggleUploadSection}>
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={uploadSectionExpanded}>
          <CardContent>
            {sections.map((section) => (
              <FileUploadSection
                key={section.fileUse}
                section={section}
                showApprovalSection={showApprovalSection}
              />
            ))}
          </CardContent>
        </Collapse>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="QC Process"
          action={
            <IconButton onClick={toggleQcSection}>
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={qcSectionExpanded}>
          <CardContent>
            <Box mt={2} textAlign="center">
              {qcInProgress && (
                <>
                  <Typography>
                    This process can take up to three minutes.
                  </Typography>
                  <Typography>
                    Why so long? We're checking your uploaded documents for
                    signatures, address updates, and other important
                    information.
                  </Typography>
                  <Typography>Thank you for your patience.</Typography>
                </>
              )}
              {qcResults && <QcResultComponent result={qcResults} />}
            </Box>
          </CardContent>
        </Collapse>
      </Card>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="contained"
          onClick={startQcCheck}
          disabled={qcInProgress || !canStartQc} // Disable button if QC is in progress or required files are not uploaded
          sx={{ mt: 2, width: "200px", height: "50px" }}
        >
          {qcInProgress ? <CircularProgress size={24} /> : "Start QC"}
        </Button>
      </Box>
    </Box>
  );
};

export default FileUpload;
