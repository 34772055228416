import React, { useEffect } from "react";
import { Box, CircularProgress, Typography, Paper } from "@mui/material";
import { useInvoiceFormContext } from "../../../contexts/InvoiceFormContext";
import { useQuoteData } from "../../../hooks/invoices/useQuoteData"; // Import the combined custom hook
import QuoteList from "./display/QuoteList";
import CustomerAutocomplete from "./fields/CustomerAutocomplete";

const QuoteLookup: React.FC = () => {
  const {
    customerQuotes,
    loadingQuotes,
    loadingQuoteDetails,
    error,
    success,
    handleQuoteLookup,
  } = useQuoteData();
  const { setCanAdvance, setBlockReason } = useInvoiceFormContext();

  useEffect(() => {
    if (loadingQuoteDetails) {
      setCanAdvance(false);
      setBlockReason("Still loading quote data!");
    } else {
      setCanAdvance(true);
      setBlockReason("");
    }
  }, [loadingQuoteDetails, setCanAdvance, setBlockReason]);

  return (
    <Box>
      <CustomerAutocomplete />
      {(loadingQuotes || loadingQuoteDetails) && (
        <Paper
          elevation={3}
          sx={{
            mt: 2,
            p: 2,
            backgroundColor: "#e0f7fa", // Light blue background
            color: "#00796b", // Darker blue text
          }}
        >
          <Box display="flex" alignItems="center">
            <CircularProgress size={24} />
            <Typography variant="body2" ml={2}>
              {loadingQuotes
                ? "Fetching quotes..."
                : "Loading invoice details..."}
            </Typography>
          </Box>
        </Paper>
      )}
      {error && (
        <Typography variant="body2" color="error" mt={2}>
          {error}
        </Typography>
      )}
      {success && (
        <Typography variant="body2" color="primary" mt={2}>
          Quote data successfully loaded!
        </Typography>
      )}
      {customerQuotes.length > 0 && (
        <QuoteList quotes={customerQuotes} onQuoteClick={handleQuoteLookup} />
      )}
    </Box>
  );
};

export default QuoteLookup;
