import { useState, useEffect } from "react";
import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import { useAuth } from "src/contexts/AuthContext";

type InvoiceLookup = {
  transactionId: string;
  customerName: string;
  invoiceAmount: number;
  poNumber: string;
};

export const useInvoiceLookupByTransactionId = (transactionId: string) => {
  const [invoice, setInvoice] = useState<InvoiceLookup | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { fetchWithReauth } = useAuth();

  const fetchInvoiceDetails = async (transactionId: string) => {
    if (!transactionId) {
      setInvoice(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await fetchWithReauth(
        `${API_URL}/netsuite/invoice-lookup-by-transaction-id?transactionId=${transactionId}`
      );
      const data = await checkResponse(response);
      setInvoice(data);
    } catch (error) {
      console.error("Error fetching invoice details:", error);
      setError("Failed to fetch invoice details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoiceDetails(transactionId);
  }, [transactionId]);

  return {
    invoice,
    loading,
    error,
    refetch: () => fetchInvoiceDetails(transactionId),
  };
};
