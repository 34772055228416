// InvoiceOutputSteps.tsx
import React from "react";
import { Box, Typography, Link, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import LinkIcon from "@mui/icons-material/Link";
import { generateNetsuiteLink } from "utils/netsuite";

const isProd = () => {
  return window.location.origin.split(".")[0] === "https://servicedesk";
};

// Base Component
interface InvoiceStepDisplayBaseProps {
  name: string;
  status: string;
  link?: string;
  children?: React.ReactNode;
}

const InvoiceStepDisplayBase: React.FC<InvoiceStepDisplayBaseProps> = ({
  name,
  status,
  link,
  children,
}) => {
  let icon;
  let color;
  let tooltipText;

  switch (status) {
    case "COMPLETED":
      icon = <CheckIcon />;
      color = "green";
      tooltipText = "Completed";
      break;
    case "FAILURE":
      icon = <ErrorIcon />;
      color = "red";
      tooltipText = "Failed";
      break;
    case "IN_PROGRESS":
      icon = <HourglassEmptyIcon />;
      color = "orange";
      tooltipText = "In Progress";
      break;
    case "NOT_INITIATED":
    default:
      icon = <NotStartedIcon />;
      color = "grey";
      tooltipText = "Not Initiated";
      break;
  }

  return (
    <Box mb={2} width="300px" height="60px">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="left"
        gap={1}
        height="32px"
      >
        <Tooltip title={tooltipText}>
          <Box
            component="span"
            color={color}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {icon}
          </Box>
        </Tooltip>
        {link && (
          <Link href={link} target="_blank" rel="noopener noreferrer">
            <LinkIcon />
          </Link>
        )}
        <Typography
          variant="body1"
          flexGrow={1}
          sx={{ fontWeight: "bold", marginRight: 1 }}
        >
          {name}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

// SubsidiaryCheckStep Component
interface SubsidiaryCheckStepProps {
  name: string;
  status: string;
  subsidiaryId?: string;
}

const SubsidiaryCheckStep: React.FC<SubsidiaryCheckStepProps> = ({
  name,
  status,
  subsidiaryId,
}) => (
  <InvoiceStepDisplayBase
    name={name}
    status={status}
    link={
      subsidiaryId
        ? generateNetsuiteLink(
            isProd() ? "4914352" : "4914352-sb2",
            "subsidiary",
            subsidiaryId,
          )
        : undefined
    }
  >
    {subsidiaryId && (
      <Typography variant="body2">Subsidiary ID: {subsidiaryId}</Typography>
    )}
  </InvoiceStepDisplayBase>
);

// BillingAccountCreationStep Component
interface BillingAccountCreationStepProps {
  name: string;
  status: string;
  billingAccountId?: string;
}

const BillingAccountCreationStep: React.FC<BillingAccountCreationStepProps> = ({
  name,
  status,
  billingAccountId,
}) => (
  <InvoiceStepDisplayBase
    name={name}
    status={status}
    link={
      billingAccountId
        ? generateNetsuiteLink(
            isProd() ? "4914352" : "4914352-sb2",
            "billingaccount",
            billingAccountId,
          )
        : undefined
    }
  >
    {billingAccountId && (
      <Typography variant="body2">
        Billing Account ID: {billingAccountId}
      </Typography>
    )}
  </InvoiceStepDisplayBase>
);

// CustomPricingPlansCreationStep Component
interface CustomPricingPlansCreationStepProps {
  name: string;
  status: string;
  customPricingPlanIds?: string[];
}

const CustomPricingPlansCreationStep: React.FC<
  CustomPricingPlansCreationStepProps
> = ({ name, status, customPricingPlanIds }) => (
  <InvoiceStepDisplayBase name={name} status={status}>
    {customPricingPlanIds && (
      <Typography variant="body2">
        Custom Pricing Plan IDs: {customPricingPlanIds.join(", ")}
      </Typography>
    )}
  </InvoiceStepDisplayBase>
);

// SubscriptionCreationStep Component
interface SubscriptionCreationStepProps {
  name: string;
  status: string;
  subscriptionId?: string;
}

const SubscriptionCreationStep: React.FC<SubscriptionCreationStepProps> = ({
  name,
  status,
  subscriptionId,
}) => (
  <InvoiceStepDisplayBase
    name={name}
    status={status}
    link={
      subscriptionId
        ? generateNetsuiteLink(
            isProd() ? "4914352" : "4914352-sb2",
            "subscription",
            subscriptionId,
          )
        : undefined
    }
  >
    {subscriptionId && (
      <Typography variant="body2">Subscription ID: {subscriptionId}</Typography>
    )}
  </InvoiceStepDisplayBase>
);

// ContractualDocumentsCreationStep Component
interface ContractualDocumentsCreationStepProps {
  name: string;
  status: string;
  contractualDocumentId?: string;
}

const ContractualDocumentsCreationStep: React.FC<
  ContractualDocumentsCreationStepProps
> = ({ name, status, contractualDocumentId }) => (
  <InvoiceStepDisplayBase
    name={"Document Upload"}
    status={status}
    link={
      contractualDocumentId
        ? generateNetsuiteLink(
            isProd() ? "4914352" : "4914352-sb2",
            "customrecord",
            contractualDocumentId,
            "697",
          )
        : undefined
    }
  >
    {contractualDocumentId && (
      <Typography variant="body2">
        Contractual Document ID: {contractualDocumentId}
      </Typography>
    )}
  </InvoiceStepDisplayBase>
);

// DraftItemsActivationStep Component
interface DraftItemsActivationStepProps {
  name: string;
  status: string;
}

const DraftItemsActivationStep: React.FC<DraftItemsActivationStepProps> = ({
  name,
  status,
}) => <InvoiceStepDisplayBase name={name} status={status} />;

// SubscriptionActivationStep Component
interface SubscriptionActivationStepProps {
  name: string;
  status: string;
  subscriptionActivationId?: string;
}

const SubscriptionActivationStep: React.FC<SubscriptionActivationStepProps> = ({
  name,
  status,
  subscriptionActivationId,
}) => (
  <InvoiceStepDisplayBase
    name={name}
    status={status}
    link={
      subscriptionActivationId
        ? generateNetsuiteLink(
            isProd() ? "4914352" : "4914352-sb2",
            "subscriptionactivation",
            subscriptionActivationId,
          )
        : undefined
    }
  >
    {subscriptionActivationId && (
      <Typography variant="body2">
        Subscription Activation ID: {subscriptionActivationId}
      </Typography>
    )}
  </InvoiceStepDisplayBase>
);

// HandleAutoRenewalStep Component
interface HandleAutoRenewalStepProps {
  name: string;
  status: string;
}

const HandleAutoRenewalStep: React.FC<HandleAutoRenewalStepProps> = ({
  name,
  status,
}) => <InvoiceStepDisplayBase name={name} status={status} />;

// InvoiceCreationStep Component
interface InvoiceCreationStepProps {
  name: string;
  status: string;
  invoiceId?: string;
}

const InvoiceCreationStep: React.FC<InvoiceCreationStepProps> = ({
  name,
  status,
  invoiceId,
}) => (
  <InvoiceStepDisplayBase
    name={name}
    status={status}
    link={
      invoiceId
        ? generateNetsuiteLink(
            isProd() ? "4914352" : "4914352-sb2",
            "invoice",
            invoiceId,
          )
        : undefined
    }
  >
    {invoiceId && (
      <Typography variant="body2">Invoice ID: {invoiceId}</Typography>
    )}
  </InvoiceStepDisplayBase>
);

// InvoiceItemDescriptionUpdateStep Component
interface InvoiceItemDescriptionUpdateStepProps {
  name: string;
  status: string;
}

const InvoiceItemDescriptionUpdateStep: React.FC<
  InvoiceItemDescriptionUpdateStepProps
> = ({ name, status }) => (
  <InvoiceStepDisplayBase name={name} status={status} />
);

// InvoiceMetadataUpdateStep Component
interface InvoiceMetadataUpdateStepProps {
  name: string;
  status: string;
}

const InvoiceMetadataUpdateStep: React.FC<InvoiceMetadataUpdateStepProps> = ({
  name,
  status,
}) => <InvoiceStepDisplayBase name={name} status={status} />;

interface SendInvoiceStepProps {
  name: string;
  status: string;
}

const SendInvoiceStep: React.FC<SendInvoiceStepProps> = ({ name, status }) => (
  <InvoiceStepDisplayBase name={name} status={status} />
);

export {
  SubsidiaryCheckStep,
  BillingAccountCreationStep,
  CustomPricingPlansCreationStep,
  SubscriptionCreationStep,
  ContractualDocumentsCreationStep,
  DraftItemsActivationStep,
  SubscriptionActivationStep,
  InvoiceCreationStep,
  InvoiceItemDescriptionUpdateStep,
  InvoiceMetadataUpdateStep,
  HandleAutoRenewalStep,
  SendInvoiceStep,
};
