import { useState, useCallback } from "react";
import { ExtractedDocumentData, InvoiceFormData } from "models/invoice";
import { PaymentTerm } from "models/netsuite";
import { useInvoiceFormContext } from "../../contexts/InvoiceFormContext";
import { useAuth } from "../../contexts/AuthContext";

export const useQcCheck = () => {
  const { invoiceFormData, setInvoiceFormData } = useInvoiceFormContext();
  const [qcResults, setQcResults] = useState<any>(null);
  const [qcInProgress, setQcInProgress] = useState<boolean>(false);
  const [qcRequestId, setQcRequestId] = useState<string | null>(null);
  const { fetchWithReauth } = useAuth();

  const handleQcCheck = async () => {
    setQcInProgress(true);
    const keys = invoiceFormData.uploadedFileKeys || [];
    try {
      const response = await fetchWithReauth("/api/invoices/doc-qc", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uploadedKeys: keys }),
      });

      if (!response.ok) {
        throw new Error("Failed to initiate QC check");
      }

      const result = await response.json();
      setQcRequestId(result.requestId);
      pollQcStatus(result.requestId);
    } catch (error) {
      console.error("QC check initiation failed:", error);
      setQcInProgress(false);
    }
  };

  const pollQcStatus = useCallback((requestId: string) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetchWithReauth(`/api/invoices/doc-qc/${requestId}`, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error("Failed to get QC status");
        }

        const result = await response.json();
        if (["COMPLETED", "FAILED"].includes(result.status)) {
          const outputData = JSON.parse(result.output);
          const qcResult: {
            type: "Success" | "Error";
            data: ExtractedDocumentData;
          } = outputData.qcResult;
          clearInterval(intervalId);
          setQcResults(outputData.qcResult);
          if (qcResult.type === "Success") {
            parseExtractedData(qcResult.data);
          }
          setQcInProgress(false);
        }
      } catch (error) {
        console.error("Failed to get QC status:", error);
        clearInterval(intervalId);
        setQcInProgress(false);
      }
    }, 10000);
  }, []);

  const parseExtractedData = (data: ExtractedDocumentData): void => {
    setInvoiceFormData((prevData) => ({
      ...prevData,
      billingFrequency: mapBillingSchedule(data.billingSchedule),
      paymentTerms: mapPaymentTerms(data.paymentTerms),
      shippingAddress: data.shippingAddress,
      billingAddress: data.billingAddress,
      contactEmail: data.contactEmailAddress,
      poNumber: data.poNumber.number || "",
    }));
  };

  const mapPaymentTerms = (term: string): PaymentTerm => {
    const termPhrases = [
      { phrase: "due upon signature", term: PaymentTerm.DUE_ON_RECEIPT },
      { phrase: "net 15", term: PaymentTerm.NET_15 },
      { phrase: "net 30", term: PaymentTerm.NET_30 },
      { phrase: "net 60", term: PaymentTerm.NET_60 },
      { phrase: "net 45", term: PaymentTerm.NET_45 },
      { phrase: "net 75", term: PaymentTerm.NET_75 },
      { phrase: "net 90", term: PaymentTerm.NET_90 },
    ];

    let mappedTerm = PaymentTerm.DUE_ON_RECEIPT;

    termPhrases.forEach((t) => {
      if (term.toLowerCase().includes(t.phrase)) {
        mappedTerm = t.term;
      }
    });

    return mappedTerm;
  };

  const mapBillingSchedule = (schedule: string): string => {
    const schedulePhrases = [
      { phrase: "annually", term: "Annually" },
      { phrase: "monthly", term: "Monthly" },
      { phrase: "quarterly", term: "Quarterly" },
    ];

    let mappedSchedule = "One Time";

    schedulePhrases.forEach((s) => {
      if (schedule.toLowerCase().includes(s.phrase)) {
        mappedSchedule = s.term;
      }
    });

    return mappedSchedule;
  };

  return { qcResults, qcInProgress, handleQcCheck };
};
